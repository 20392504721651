<!-- 夜审记录 -->
<template>
  <section class="cont nightAuditRecords">
    <Breadcrumb :crumbs="crumbs" />
    <el-row class="content-box">
      <el-row class="table-box">
        <!-- 表格 -->
        <el-table :data="tableData" border fit stripe v-loading="loading">
          <el-table-column type="index" label="序号" width="70"></el-table-column>
          <el-table-column prop="date" label="营业时间"></el-table-column>
          <el-table-column prop="createTime" label="夜审时间"></el-table-column>
          <el-table-column label="夜审状态">
            <template slot-scope="scope">
              <div :class="scope.row.status === 'YES' ? '' : 'c-red'">
                {{ scope.row.status === 'YES' ? '成功' : '失败' }}
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="orderCount" label="失败订单"></el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-button type="text" @click="startAgain(scope.row)" v-if="scope.row.status !== 'YES'">
                <el-link type="primary">重新夜审</el-link>
              </el-button>
              <el-button type="text" @click="nightAuditLog(scope.row)">
                <el-link type="primary">夜审日志</el-link>
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页 -->
        <pagination v-show="total" :total="total" :page-size="limit" @handleSizeChangeSub="pageChange" @handleCurrentChangeSub="handlePaging" />
      </el-row>
    </el-row>
    <!-- 重新夜审 -->
    <el-dialog title="重新夜审" :visible.sync="dialogVisible" width="30%">
      <span>查看夜审日志异常订单，如果全部确认已处理，可重新夜审成功。 是否重新夜审？</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取消</el-button>
        <el-button type="primary" @click="determine">确定</el-button>
      </span>
    </el-dialog>
    <!-- 夜审日志 -->
    <el-dialog title="夜审日志" :visible.sync="logShow" width="30%" class="log" v-if="list.length">
      <span>当前：未入住{{ list[0].notCheckinCount }}间、未退房{{ list[0].notCheckoutCount }}间、未平账{{ list[0].notPayCount }}间</span>
      <div>
        <el-button type="text" @click="gotoOrder" v-if="status !== 'YES'">异常订单处理</el-button>
      </div>
      <el-timeline :style="status === 'YES' ? { marginTop: '15px' } : ''">
        <el-timeline-item :timestamp="item.createTime" placement="top" v-for="item in list" :key="item.id">
          <el-card>
            <h4 class="m-bottom-5">操作人：{{ item.createOperator || '系统' }} {{ item.source ? '自动夜审' : '重新夜审' }}</h4>
            <p>夜审数据统计如下：</p>
            <p>未入住：{{ item.notCheckinCount }}单</p>
            <p>未退房：{{ item.notCheckoutCount }}单</p>
            <p>未平账：{{ item.notPayCount }}单</p>
          </el-card>
        </el-timeline-item>
      </el-timeline>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="logShow = false">关闭</el-button>
      </span>
    </el-dialog>
  </section>
</template>

<script>
  import { getPage, nightTrialTotal, getList } from '@/api/pms/nightAudit/nightAudit'
  import { mapState } from 'vuex'
  export default {
    name: 'nightAuditRecords',
    data() {
      return {
        crumbs: new Map([['PMS'], ['夜审中心'], ['夜审记录']]),
        tableData: [],
        dialogVisible: false,
        date: '',          // 查询日期
        status: 'YES',     // 夜审状态
        time: '',          // 夜审时间
        logShow: false,
        list: [],          // 夜审日志
        loading: true,
        total: 0,          // 用户列表总条目数
        page: 1,           // 当前页 默认第一页
        limit: 10,         // 每页显示数
      }
    },
    computed: {
      ...mapState(['hotelInfo']),
    },
    mounted() {
      this.getData()
    },
    methods: {
      // 夜审记录
      getData() {
        let params = {
          limit: this.limit,
          page: this.page,
        }
        let data = {
          hotelId: this.hotelInfo.id,
        }
        getPage(params, data).then(({ success, records, total }) => {
          if (success) {
            this.tableData = records
            this.loading = false
            this.total = total
          }
        })
      },
      // 重新夜审
      startAgain(item) {
        this.dialogVisible = true
        this.time = item.date
      },
      // 确定
      determine() {
        let data = {
          hotelId: this.hotelInfo.id,
          time: this.time,
          source: false,
        }
        nightTrialTotal(data).then(({ success }) => {
          if (success) {
            this.dialogVisible = false
            this.$message({ showClose: true, message: '重审成功！', type: 'success' })
            this.getData()
          }
        })
      },
      // 夜审日志
      nightAuditLog(item) {
        let data = {
          nightTrialId: item.id,
        }
        getList(data).then(({ success, records }) => {
          if (success) {
            this.list = records.reverse()
            this.logShow = true
            this.date = item.createTime.split(' ')[0]
            this.status = item.status
          }
        })
      },
      // 跳转异常订单
      gotoOrder() {
        this.$router.push({ path: `/pms/abnormal_orders?date=${this.date}` })
      },
      // 改变每页数
      pageChange(num) {
        this.limit = num
        this.getData()
      },
      // 改变当前页
      handlePaging(num) {
        this.page = num
        this.getData()
      },
    },
  }
</script>

<style lang="scss" scoped>
  .nightAuditRecords {
    .log {
      /deep/ .el-dialog__body {
        padding-top: 10px;
      }
      /deep/ .el-timeline {
        height: 364px;
        overflow-y: auto;
        overflow-x: hidden;
        &::-webkit-scrollbar {
          width: 10px;
          height: 1px;
        }
        &::-webkit-scrollbar-thumb {
          /*滚动条里面小方块*/
          border-radius: 10px;
          background-color: #087ffd;
          background-image: -webkit-linear-gradient(
            45deg,
            rgba(255, 255, 255, 0.2) 25%,
            transparent 25%,
            transparent 50%,
            rgba(255, 255, 255, 0.2) 50%,
            rgba(255, 255, 255, 0.2) 75%,
            transparent 75%,
            transparent
          );
        }
        &::-webkit-scrollbar-track {
          /*滚动条里面轨道*/
          box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
          background: #ededed;
          border-radius: 10px;
        }
        .el-timeline-item{
          left: 2px;
          .el-timeline-item__content {
            width: 95%;
          }
        }
      }
    }
  }
</style>
